import { useState } from 'react';

export default function useForm(defaults, setErrors = {} as any) {
  const [values, setValues] = useState(defaults);

  const updateValueManually = (name, value: any) => {
    // console.log(typeof name, value);
    setValues({
      ...values,
      [name]: value,
    });
  };

  const populateValues = (values) => {
    for (const key in values) {
      setValues({
        // copy the existing values into it
        ...values,
        // update the new value that changed
        [key]: values[key],
      });
    }
  };

  function updateValue(e) {
    // check if its a number and convert
    let { value } = e.target;
    if (e.target.type === 'number') {
      value = parseInt(e.target.value);
    }

    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }

    if (typeof setErrors === 'function')
      setErrors(prevErrors => {
        const updatedErrors = { ...prevErrors };

        // Assuming errors are in sections user, store, company.
        const sections = ['user', 'store', 'company'];
        for (const section of sections) {
          if (updatedErrors[section]?.[e.target.name] !== undefined) {
            updatedErrors[section][e.target.name] = "";
            break;
          }
        }

        return updatedErrors;
      });

    setValues({
      // copy the existing values into it
      ...values,
      // update the new value that changed
      [e.target.name]: value,
    });
  }

  return { values, updateValue, updateValueManually, populateValues, setValues };
}
