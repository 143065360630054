"use client"
import styled from "styled-components";
import { device } from "../../utils/deviceSizes";

export const FormStyles = styled.form`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  max-width: 80rem;
  /* box-shadow: var(--box-shadow); */
  background: var(--lightGrey);
  color: #000;

  &.no-bg {
    background: #fff;
  }


  gap: 2rem;
  label {
    margin: 0 !important;
  }

  .title {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: 400;
  }

  @media ${device.tablet} {
    min-width: 30rem;
    margin: 0 1rem;
    padding: 1.5rem;
  }
  .form-control {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    /* margin-bottom: 2rem; */

    input {
      /* border: 1px solid #4f4f4f !important; */
      color: #000 !important;
    }
    &__row {
      flex-direction: row;
      width: 50%;
      input {
        max-width: 8rem;
      }
    }

    &__date {
      label {
        width: 100%;
        input {
          width: 20rem;
        }
      }
    }

    &.select-box {
      max-height: 40vh;
      width: fit-content;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    @media ${device.tablet} {
      width: 100%;
    }

    &.textarea {
      flex-direction: column;

      label {
        margin-bottom: 1rem;
      }
      textarea {
        background: var(--lightGrey2);
        width: 100%;
      }
    }

    label {
      display: flex;
      flex-direction: column;
    }

    input:not([type='checkbox']),
    select {
      width: 30rem;
      height: 3rem;

      background-color: #fff;
      padding: 0 0.5rem;
      border: none;

      @media ${device.tablet} {
        width: 100%;
      }
    }

    .number-input {
      display: flex;
      align-items: center;
      span {
        margin-right: 1rem;
      }
      input {
        width: 7rem;
      }
    }

    &.phone-select {
      display: grid;
      grid-template-columns: 10rem 1fr;

      gap: 0;
      column-gap: 1rem;
      #country-code,
      input {
        height: 3rem !important;
      }

      input {
        padding: 2.2rem 1rem;
        width: 100%;
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      label {
        margin: 0;
      }

      .form-field-title {
        grid-column: 1/3;
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 15%;
    button {
      margin-right: 1rem;
      text-transform: uppercase;
      width: 100%;
    }
  }

  a {
    color: #1a4dbb;
    margin: 1rem 0;
  }

  .message {
    color: var(--green);
    margin: 1rem 0;
    max-width: 50rem;
    text-align: center;
  }
`;
