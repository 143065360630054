export const useErrorMessage = (error) => {
    let errorMessage = null;
    if (!error || !error.message) return null;
  
    if (error.networkError && error.networkError.result && error.networkError.result.errors.length) {
      errorMessage = error.networkError.result.errors.map((error) =>
        error.message.replace('GraphQL error: ', '')
      );
    }
    errorMessage = error.message.replace('GraphQL error: ', '');
    return errorMessage;
};
  